import requestConfigs from '@/api/requestConfigs'

export default {
	developers: {
		title: 'general.developers',
		img: require('@/modules/list/assets/developers.webp'),
		imgMobile: require('@/modules/list/assets/developersMobile.webp'),
		request: requestConfigs.GETDevelopers,
		card: 'CommonCard',
		alt: 'Developer logo',
    isFull: true,
		cardImageSizes: { width: 280, height: 167 },
		shadow:
			'linear-gradient(179.54deg, rgba(0, 0, 0, 0.5) 0.4%, rgba(0, 0, 0, 0) 38.2%)'
	},
	complexes: {
		title: 'general.complexes',
		img: require('@/modules/list/assets/complexes-areas.webp'),
		imgMobile: require('@/modules/list/assets/complexes-areasMobile.webp'),
		request: requestConfigs.GETComplexList,
		card: 'ProjectCard',
		alt: 'Photo complex',
    isFull: true,
		cardImageSizes: { width: 280, height: 167 },
		shadow: 'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))'
	},
	areas: {
		title: 'general.areas',
		img: require('@/modules/list/assets/complexes-areas.webp'),
		imgMobile: require('@/modules/list/assets/complexes-areasMobile.webp'),
		request: requestConfigs.GETRegionList,
		card: 'CommonCard',
		alt: 'Photo area',
    isFull: true,
		cardImageSizes: { width: 280, height: 167 },
		shadow: 'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))'
	},
	favorite: {
		title: 'general.favorite',
		card: 'EstateCard',
		alt: 'Photo room',
    isFull: false,
		shadow: 'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))'
	},
	projects: {
		title: 'general.projects',
		img: require('@/modules/list/assets/projects.webp'),
		imgMobile: require('@/modules/list/assets/projectsMobile.webp'),
		card: 'ProjectCard',
		alt: 'Project logo',
    isFull: true,
		shadow: 'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))'
	}
}
