<template>
	<button class="sort btn">
		{{ $t('web.alphabet_sort') }}
		<!--	Добавляем по отдельности к каждой иконке класс active, в зависимости от выбранного фильтра	-->
		<or-icon class="sort__icon" type="pointer-arrow" />
		<or-icon class="sort__icon" type="pointer-arrow" />
	</button>
</template>
<script>
export default {
	name: 'Sort'
}
</script>
<style lang="scss">
.sort {
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: #808080;
	background-color: transparent;
	position: relative;
	display: flex;
	align-items: center;
	&__icon {
		position: relative;
		display: block;
		&:first-child {
			margin-left: 5px;
		}
		&:last-child {
			transform: rotate(180deg);
		}
		&.active {
			color: #c99d56;
		}
	}
}
</style>
