<template>
	<div class="list-page">
		<div class="container">
			<template v-if="config.isFull">
				<transition name="fade" mode="out-in">
					<Full
						class="list-page__full-block"
						:title="config.title"
						:background="img"
						:background-shadow="config.shadow"
						v-if="!isLoading"
					/>
					<or-skeleton v-else style="width: 100%; height: 260px" />
				</transition>
			</template>
			<div class="list-page__content">
				<transition name="fade" mode="out-in">
					<div class="list-page__top">
						<div>
							<h2
								:class="{ 'list-page--font': type === 'favorite' }"
								class="list-page__title"
								v-if="!isLoading"
							>
								{{ $t(config.title) }}
								<!--            <div>{{list.length || 0}}</div>-->
							</h2>
							<or-skeleton
								v-else
								style="max-width: 150px; width: 100%; height: 26px"
							/>
						</div>
						<div>
              <sort-panel v-if="!isLoading" :list="[{ key: 'title', title: 'web.alphabet_sort' }]" @update="updateSort" />
<!--							<Sort v-if="!isLoading" />-->
							<or-skeleton
								v-else
								style="max-width: 50px; width: 100%; height: 18px"
							/>
						</div>
					</div>
				</transition>
				<transition name="fade" mode="out-in">
					<div class="list-page__list" v-if="!isLoading">
						<component
							v-for="item in list"
							:is="config.card"
							:key="item.id"
							:content="item"
							:type="type"
							:alt="config?.alt"
							:image-sizes="config.cardImageSizes"
							class="list-page__item"
							:class="{ logo: type === 'developers' }"
							:isLoading="isLoading"
							@click="$emit('cardClick', $event, item.slug)"
						/>
					</div>
					<div class="list-page__list" v-else>
						<div class="skeleton-card" v-for="item in 8" :key="item">
							<or-skeleton
								style="width: 100%; margin-bottom: 15px"
								class="skeleton-card__img"
							/>
							<or-skeleton
								style="width: 150px; height: 18px; margin-bottom: 5px"
								class="skeleton-card__text"
							/>
							<or-skeleton
								style="width: 100px; height: 15px"
								class="skeleton-card__text"
							/>
						</div>
					</div>
				</transition>
			</div>
		</div>
	</div>
</template>

<script>
import debounce from 'lodash/debounce'
import config from './enums/config.js'

import EstateCard from '@/components/cards/Estate.vue'
import ProjectCard from '@/components/cards/Project.vue'
import CommonCard from '@/components/cards/Common.vue'
import Full from './components/Full.vue'
import Sort from '@/modules/list/components/Sort.vue'
import SortPanel from "@/components/SortPanel/index.vue";

export default {
	props: {
		type: {
			type: String,
			required: true
		},
		list: {
			type: Array,
			default: () => []
		},
		isLoading: {
			type: Boolean,
			default: false
		},
		meta: {
			type: Object,
			default: () => ({})
		}
	},
	components: { Sort, Full, EstateCard, ProjectCard, CommonCard, SortPanel },
	data() {
		return {
			scrollElement: null,
			isMobile: false
		}
	},
	mounted() {
		this.scrollElement = document.getElementsByTagName('html')[0]

		this.handleDebouncedScroll = debounce(this.handleScroll, 100)
		window.addEventListener('scroll', this.handleDebouncedScroll, {
			passive: true
		})
		window.addEventListener('resize', this.detectResize, { passive: true })
		this.isMobile = window.innerWidth < 601
	},
	onUnmounted() {
		window.removeEventListener('resize', this.detectResize)
		window.removeEventListener('scroll', this.handleDebouncedScroll)
	},
	methods: {
		async handleScroll() {
			if (
				this.scrollElement?.scrollTop + this.scrollElement?.clientHeight >=
				this.scrollElement?.scrollHeight - 600
			) {
				if (this.isLoading) {
					return
				}

				// // Проверяем остались ли посты
				if (this.meta.current_page < this.meta.last_page) {
					await this.$emit('getData')
				}
			}
		},
		detectResize() {
			this.isMobile = window.innerWidth < 601
		},
    async updateSort() {
      await this.$emit('getData', true)
    }
	},
	computed: {
		config() {
			return config[this.type] || {}
		},
		img() {
			return this.isMobile ? this.config.imgMobile : this.config.img
		}
	}
}
</script>

<style lang="scss">
.list-page {
	position: relative;
	width: 100%;
	max-width: 1180px;
	margin: 0 auto;
	height: 100%;

	&--font {
		font-size: 28px !important;
		display: flex;
		justify-content: space-between;

		& > div {
			color: $brown-main;
		}
	}

	&__title {
		margin: 0;
		font-weight: 700;
		font-size: 18px;
		line-height: 25px;
	}

	&__content {
		position: relative;
		padding-bottom: 110px;
	}

	&__loader {
		position: absolute;
		bottom: 65px;
		left: 0;
		right: 0;
		margin: 0 auto;
	}

	&__list {
		display: grid;
		grid-gap: 20px;
		grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
		.skeleton-card {
			&__img {
				aspect-ratio: 16 / 10;
				height: unset;
			}
		}
		.estate-card__img-wrapper {
			.skeleton-basic {
				width: 100%;
			}
		}
	}

	&__item {
		margin-bottom: 40px;
	}

	&__top {
		margin: 20px 0 30px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		flex-wrap: wrap;
	}
}
</style>
