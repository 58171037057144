<template>
	<div class="full-into" :style="styles">
		<h1 class="full-into__title">{{ $t(title) }}</h1>
		<p class="full-into__desc">{{ $t('general.buyPropertyDubai') }}</p>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			required: true
		},
		background: {
			type: [String, Object],
			required: true
		},
		backgroundShadow: {
			type: [String, Object],
			required: true
		}
	},
	computed: {
		styles() {
			return {
				'background-image': `${this.backgroundShadow},url(${this.background})`
			}
		}
	}
}
</script>

<style lang="scss">
.full-into {
	position: relative;
	min-height: 260px;
	padding: 0 8.5%;
	padding-top: 80px;
	color: #ffffff;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	border-radius: 4px;
	overflow: hidden;

	&__title {
		position: relative;
		z-index: 2;
		margin: 0 0 20px 0;
		font-weight: 700;
		font-size: 48px;
		line-height: 115.18%;

		@media (max-width: 768px) {
			font-size: 38px;
			margin-bottom: 10px;
		}

		@media (max-width: 550px) {
			font-size: 28px;
			margin-bottom: 5px;
		}
	}

	&__desc {
		position: relative;
		z-index: 2;
		margin: 0;
		font-weight: 400;
		font-size: 20px;
		line-height: 27px;
	}
}
</style>
